<template>
  <Toast></Toast>
  <ConfirmDialog />
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '450px' }"
    header="ACTUALIZAR FECHAS DE VENCIMIENTOS"
    :modal="true"
    @hide="closeModal"
    class="p-fluid"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <h4 class="text-center">
            <strong>{{ data_actualizarFechaVence.nombre_producto }}</strong>
          </h4>
          <p>
            <strong>SUCURSAL ACTUAL: </strong>
            <strong>{{ data_actualizarFechaVence.sucursal_id }}</strong>
          </p>
          <p>
            <strong>FECHA VENCE ACTUAL: </strong>
            <strong class="p-invalid">{{
              data_actualizarFechaVence.fecha_vence
            }}</strong>
          </p>
          <p>
            <strong>CANTIDAD M&Aacute;XIMA: </strong>
            <strong> {{ data_actualizarFechaVence.cantidad }}</strong>
          </p>
        </div>

        <div class="field col-12 md:col-6">
          <span
            ><strong>CANTIDAD A ACTUALIZAR: </strong
            ><small class="p-invalid">*</small></span
          >
          <InputNumber
            v-model="data_actualizarFechaVence.cantidad_actualizar"
            required="true"
            :minFractionDigits="0"
            :useGrouping="false"
            mode="decimal"
            showButtons
            :min="0"
            :max="data_actualizarFechaVence.cantidad"
            :inputClass="'text-right'"
          />
        </div>

        <div class="field col-12 md:col-6">
          <span
            ><strong>FECHA DE VENCIMIENTO: </strong>
            <small class="p-invalid">*</small></span
          >
          <Calendar
            v-model="data_actualizarFechaVence.fecha_vence_actualizar"
            dateFormat="dd/mm/yy"
            showIcon
            :inputStyle="{ width: '100%' }"
            :disabled="data_actualizarFechaVence.sin_fecha_vence ? true : false"
          />
        </div>

        <div class="field col-12 md:col-12">
          <div class="p-inputgroup">
            <span><strong>FECHA DE VENCIMIENTO: </strong></span>
            <div class="p-field-checkbox">
              <Checkbox
                value="sin_fecha_vence"
                v-model="data_actualizarFechaVence.sin_fecha_vence"
                :disabled="
                  data_actualizarFechaVence.fecha_vence_actualizar
                    ? true
                    : false
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="closeModal"
      />
      <Button
        label="ELIMINAR"
        icon="pi pi-trash"
        class="p-button-danger p-button-lg"
        @click="deleteFechaVence"
        :loading="eliminando"
      />
      <Button
        label="ACTUALIZAR"
        icon="pi pi-check"
        class="p-button-info p-button-lg"
        @click="guardarNuevaFechaVence"
        :disabled="!enviado ? false : true"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>
<script>
import OCRecepcionService from "@/service/OCRecepcionService.js";

export default {
  name: "ActualizarFechaVence",
  emits: ["closeModal", "actualizarlistado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    actualizarFechaVence: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  oCRecepcionService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_actualizarFechaVence: this.actualizarFechaVence,
      enviado: false,
      errors: {},
      eliminando: false,
    };
  },
  created() {
    this.oCRecepcionService = new OCRecepcionService();
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
      this.enviado = false;
    },
    guardarNuevaFechaVence() {
      this.enviado = true;

      // validaciones de campos requeridos
      if (
        this.data_actualizarFechaVence.cantidad_actualizar == "" ||
        this.data_actualizarFechaVence.cantidad_actualizar == null
      ) {
        this.enviado = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe ingresar la cantidad a actualizar",
          life: 3000,
        });
        return;
      }

      // preparo mis datos a enviar por request
      let data = {
        id: this.data_actualizarFechaVence.id,
        producto_id: this.data_actualizarFechaVence.producto_id,
        cantidad_actualizar: this.data_actualizarFechaVence.cantidad_actualizar,
        fecha_vence_actualizar:
          this.data_actualizarFechaVence.fecha_vence_actualizar,
        sin_fecha_vence: this.data_actualizarFechaVence.sin_fecha_vence,
        sucursal_id: this.data_actualizarFechaVence.sucursal_id,
      };

      this.oCRecepcionService
        .actualizarFechaVence(data)
        .then(() => {
          this.enviado = false;
          this.$emit("actualizarlistado");
          this.closeModal();
          this.$toast.add({
            severity: "success",
            summary: "Correcto",
            detail: "Se actualizo la fecha de vencimiento",
            life: 3000,
          });
        })
        .catch(() => {
          this.enviado = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "No se pudo actualizar la fecha de vencimiento",
            life: 3000,
          });
        });
    },
    deleteFechaVence() {
      this.$confirm.require({
        header: "CONFIRMACIÓN",
        message: "¿Está seguro de eliminar la fecha de vencimiento?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "ELIMINAR",
        rejectLabel: "CANCELAR",
        acceptClass: "p-button-primary p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        closable: false,
        closeOnEscape: false,
        accept: () => {
          this.eliminando = true;
          let datos = {
            id: this.data_actualizarFechaVence.id,
            producto_id: this.data_actualizarFechaVence.producto_id,
            sucursal_id: this.data_actualizarFechaVence.sucursal_id,
            cantidad_actualizar:
              this.data_actualizarFechaVence.cantidad_actualizar,
          };
          this.oCRecepcionService
            .eliminarFechaVence(datos)
            .then((data) => {
              this.eliminando = false;
              this.$emit("actualizarlistado");
              this.closeModal();
              this.$toast.add({
                severity: "success",
                summary: "Correcto",
                detail: data.message,
                life: 3000,
              });
            })
            .catch(() => {
              this.eliminando = false;
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: "No se pudo eliminar la fecha de vencimiento",
                life: 3000,
              });
            });
        },
        reject: () => {
          this.$toast.add({
            severity: "info",
            summary: "Información",
            detail: "Se cancelo la eliminación de la fecha de vencimiento",
            life: 3000,
          });
          this.eliminando = false;
        },
      });
    },
  },
  watch: {
    show(value) {
      this.mostrarModal = value;
    },
    actualizarFechaVence(value) {
      this.data_actualizarFechaVence = value;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
  font-weight: bold;
}
</style>
